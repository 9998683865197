<template>
    <div v-for="(letters,i) in keyboardRows" :key="i" class="flex flex-wrap justify-center mb-2 touch-manipulation select-none">
        <span v-for="(letter,i) in letters" :key="i"
             class="h-14 rounded flex flex-1 flex-wrap bg-gray-400 mr-0.25 justify-center items-center uppercase cursor-pointer" @click="keyPressed(letter)">
            {{ letter === "{backspace}" ? '<=' : letter === "{enter}" ? "Enter" : letter }}
        </span>
    </div>
</template>

<script>
export default {
    name: "KeyboardComponent",
    props:['guessedLetter'],
    data() {
        return {
            keyboardRows: [
                ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
                ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
                ["{enter}", "z", "x", "c", "v", "b", "n", "m", "{backspace}"],
            ],
        }
    },
    methods:{
        keyPressed(pressed){
            this.$emit('handleInput',pressed);
        }
    }
}
</script>

<style scoped>

</style>
