import API from './API';

export default {
    getAnswer(){
        return API().get('/today');
    },
    getAllWord(){
        return API().get('/all')
    }
}
