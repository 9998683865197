<template>
    <div class="bg-black">
        <div class="flex flex-col flex-wrap bg-black h-screen max-w-2xl mx-auto justify-evenly text-white">
            <div>
                <board-component v-for="(guess,i) in guesses" :key="i" :value="guess" :answer="answer[0]"
                                 :submitted="i<currentGuessIndex"></board-component>
                <keyboard-component class="block mx-2.5 select-none" @handleInput="handleInput"
                                    :guessedLetter="guesses"></keyboard-component>
            </div>
        </div>
    </div>
</template>

<script>
import BoardComponent from "@/components/BoardComponent";
import wordleAPI from "@/core/services/wordleAPI";
import KeyboardComponent from "@/components/KeyboardComponent";

export default {
    name: 'App',
    components: {
        KeyboardComponent,
        BoardComponent,
    },
    data() {
        return {
            guesses: ["", "", "", "", "", ""],
            currentGuessIndex: 0,
            answer: '',
            allEnglishWord: [],
            gameWon:false,
        }
    },
    created() {
        window.addEventListener("keyup", (e) => {
            e.preventDefault();
            let key = e.keyCode === 13 ? "{enter}" : e.keyCode === 8 ? "{backspace}" : String.fromCharCode(e.keyCode).toLowerCase();
            this.handleInput(key);
        });
        this.solution();
        this.allWord();
    },
    methods: {
        handleInput(key) {
            if (this.currentGuessIndex >= 6 || this.gameWon) {
                return;
            }
            const currentGuess = this.guesses[this.currentGuessIndex];
            if (key === "{enter}") {
                // send Guess
                if (currentGuess.length === 5) {
                    if (this.allEnglishWord.includes(currentGuess.toUpperCase())) {
                        this.currentGuessIndex++;
                    }
                }
            } else if (key === "{backspace}") {
                this.guesses[this.currentGuessIndex] = currentGuess.slice(0, -1);
            } else if (currentGuess.length < 5) {
                //Check if the input is alphabet or not
                const alphabetRegex = /^[A-Za-z]/;
                if (alphabetRegex.test(key)) {
                    this.guesses[this.currentGuessIndex] += key;
                }
            }
        },
        solution() {
            wordleAPI.getAnswer().then((response) => {
                this.answer = response.data.data;
            });
        },
        allWord() {
            wordleAPI.getAllWord().then((response) => {
                this.allEnglishWord = response.data.data;
            });
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
