<template>
    <div class="grid max-w-xs grid-cols-5 gap-1 mx-auto mb-1">
        <CellComponent v-for="i in 5" :key="i" :letter="value[i-1]" :color="color[i-1]"></CellComponent>
    </div>
</template>

<script>
import CellComponent from "@/components/CellComponent";

export default {
    name: "BoardComponent",
    components: {CellComponent},
    props: ['value', 'answer', 'submitted'],
    data() {
        return {
            color: ['','','','',''],
        }
    },
    watch: {
        async submitted() {
            if (this.submitted) {
                let a = this.answer.toLowerCase();
                let v = this.value;
                let temp = ["gray", "gray", "gray", "gray", "gray"];
                let letterPool = [];
                for (let i = 0; i < a.length; i++) {
                    if (v.charAt(i) === a.charAt(i)) {
                        temp[i] = "green";
                    } else {
                        letterPool.push(a.charAt(i));
                    }
                }
                for (let i = 0; i < a.length; i++) {
                    if (temp[i] === "gray") {
                        if (letterPool.indexOf(v.charAt(i)) !== -1) {
                            letterPool.splice(letterPool.indexOf(v.charAt(i)),1);
                            temp[i] = "yellow";
                        }
                    }
                    this.color[i]= temp[i];
                    await new Promise((resolve) => setTimeout(resolve,500 ))
                }
            }
        }
    }
}
</script>

<style scoped>
</style>
